.emptyInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  color: #707070;
}

.emptyIcon {
  max-width: 70px;
  font-size: 42px;
}

.emptyIcon img {
  width: 100%;
}

.emptyInfo p {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
