.deviceNotes {
  textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    resize: none;
  }
}

.inputRow {
  width: 100%;
  display: flex;
  align-items: flex-start;

  button {
    margin-left: 8px;
  }
}

.noteRow {
  display: flex;
}

.noteContent {
  flex-grow: 1;
  min-width: 180px;
}

.noteDate {
  min-width: 165px;
  margin-right: 8px;
  padding-top: 6px;
}

.noteAuthor {
  padding-top: 6px;
  display: inline-block;
  margin-right: 4px;
}

.noteText {
  font-weight: 700;
}

.saveButton {
  white-space: nowrap;
  overflow: hidden;
  padding: 0.375rem 1rem;
  height: 38px;
}

@media (max-width: 540px) {
  .noteRow {
    flex-wrap: wrap;
  }
}
