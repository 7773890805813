@import '../../../../../../assets/stylesheets/utils/variables.scss';

.tabPanel {
  :global(.form-control) {
    font-size: 16px;
    max-width: 160px;
    padding: 0.375rem;
    @media (min-width: 1191px) {
      max-width: 150px;
    }
  }
}

.itemsPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scrollableList {
  height: 110px;
  overflow-y: scroll;
}

.flex {
  display: flex;
}

.additionalInfo {
  font-size: 12px;
  margin-left: 8px;

  span {
    font-weight: bold;
    color: #333333;
    display: block;
  }
}

.label {
  color: #333333;
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
}

.firmwareVersionLabel {
  display: block;
  font-weight: 600;
}

.firmwareVersion {
  display: block;
  font-size: 12px;
  font-weight: 400;
}

:global {
  .flatpickr-input.form-control[readonly] {
    background-color: white;
  }
}

.bottomButtons {
  margin: 0 -24px;
  button {
    border: none;
    background-color: inherit;
    border-left: 1px solid #d2d5d7;
  }

  a,
  button {
    display: inline-block;
    text-align: center;
    padding: 7px 0;
    width: 50%;
    font-size: 13px;
    border-top: 1px solid #d2d5d7;
  }
}
