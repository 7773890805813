@import '../../../assets/stylesheets/utils/variables.scss';

.label {
  display: none;
}

.data {
  color: #707070;
  font-size: 14px;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: $mobile-max-width) {
  .tableDataContainer {
    text-align: left;
  }

  .label {
    display: block;
    color: #757575;
    font-size: 11px;
  }

  .data {
    color: black;
    font-size: 13px;
    font-weight: bold;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .hidden {
    display: none;
  }
}
