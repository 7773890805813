.infoCard {
  height: 160px;

  :global {
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px;

      .card-title {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 8px;
      }

      .card-text {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
        color: #333333;
      }
    }
  }
}

.infoCard.smaller {
  :global {
    .card-body {
      .card-title {
        font-size: 18px;
        line-height: 38px;
      }
    }
  }
}
