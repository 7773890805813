.mapMarker {
  font-size: 36px;
  transform: translate(-50%, -50%);
  transition: 0s;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  z-index: 9;

  &:hover {
    cursor: pointer;
    height: 20px;
    width: 20px;

    & + .mapMarkerTooltip {
      display: block;
    }
  }
}

.mapMarkerLabel {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 7px;
  width: 27px;
  text-align: center;
}

.mapMarkerTooltip {
  display: none;
  position: absolute;
  width: max-content;
  transform: translate(12%, -170%);
  background: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  box-shadow: 1px 1px 5px 0 rgba(55, 55, 55, 0.8);
  padding: 4px;
  z-index: 10;
}

@keyframes onHover {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1.1);
  }
}
