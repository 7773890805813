.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: auto;
  z-index: 1030;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalAtom {
  :global {
    .modal-dialog {
      overflow: auto;
      max-width: 95vw !important;
    }

    .modal-content {
      background: #0d1f2d;
      color: white;
      width: 100%;
      margin: 0 auto;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      .modal-body {
        overflow-x: auto;
      }
      .modal-body > div > table > thead > tr > th,
      .modal-body > div > table > tbody > tr > td,
      .modal-body > div > table > tbody > tr > td > pre {
        color: white;
      }
    }

    .modal-header.title-false {
      border-bottom: transparent;
    }

    .modal-header > button.close {
      color: white;
    }

    .modal-events-selector {
      display: flex;
      align-items: center;

      & * {
        flex-shrink: 0;
      }

      .button-download {
        margin-left: auto;
      }
    }
  }
}
