.digitalLabel {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  & > * {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    & > * {
      width: 50%;
    }
  }

  @media (min-width: 1192px) and (max-width: 1452px) {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
}
