.labelsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  color: black;

  @media (min-width: 520px) {
    flex-wrap: nowrap;
  }

  @media (min-width: 768px) {
    flex-direction: column;
  }
}

.labelWrapper {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.labelNameWrapper {
  flex: 1 1 auto;
  width: 1px;
}

.labelName {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.image {
  width: 100%;
  height: 100%;
}

.imageWrapper {
  position: relative;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &.border {
    border: 1px solid #d2d5d7;
    border-radius: 0.25rem;
  }
}

.imageOverflow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
}
