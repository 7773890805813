.container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  & > * {
    flex-grow: 1;
  }

  @media (min-width: 768px) {
    flex-direction: column;

    & > * {
      flex-grow: 0;
    }
  }

  @media (min-width: 1192px) and (max-width: 1452px) {
    flex-direction: row;

    & > * {
      flex-grow: 1;
    }
  }
}

.card {
  position: relative;
  padding: 1rem;
  background-color: #f5f7f9;
  border: 1px solid #d2d5d7;
  border-radius: 0.25rem;
  margin-bottom: 0;
}

.cardContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  i {
    vertical-align: inherit;
    margin-right: 0;
    color: black;
  }
}

.cardTitle {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: black;
  text-align: left;
}

.cardDescription {
  font-size: 12px;
  color: black;
  margin-bottom: 0;
  text-align: left;
}

.cardUploader {
  width: 100%;
  margin-bottom: 0;
  cursor: pointer;
}

.cardForm {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  margin-top: 0.5rem;
}

.cardInput {
  flex: auto;
  border: 1px solid #d2d5d7;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.cardSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.25rem;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 4px center;
}

.cardButton {
  flex: auto;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #24394e;
  color: white;
  text-align: left;

  &:hover {
    background-color: #1a2b3c;
  }

  &:disabled {
    background-color: #70737b;
    cursor: not-allowed;
  }
}

.errorMessage {
  width: 100%;
  margin-bottom: 0;
  color: red;
}
