.loadingInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 10px;
  color: rgba(0, 0, 0, 0.25);
  animation: fadein 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.loadingIcon svg {
  width: 42px;
  height: 42px;
  animation: rotate 3s infinite linear;
}

.loadingInfo p {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin-top: 4px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
