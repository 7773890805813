.selector {
  position: absolute;
  display: grid;
  top: 20px;
  right: 20px;
  border-radius: 6px;
  background: white;

  label {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    margin: 0 6px;

    &:first-child {
      margin-top: 6px;
    }

    &:last-child {
      margin-bottom: 6px;
    }

    input {
      vertical-align: -2px;
      margin-right: 4px;
    }

    span {
      vertical-align: middle;
    }
  }
}
