@import '../../../assets/stylesheets/utils/variables.scss';

:global {
  @media (max-width: $mobile-max-width) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='tel'],
    input[type='url'] {
      font-size: 16px;
    }
  }
}

.dashboardContainer {
  height: 100%;
}

.dashboardColumns {
  height: calc(100% - 56px);
  @media (max-width: $mobile-max-width) {
    height: 100%;
  }
}

.dashboardRow {
  display: flex;
  @media (max-width: 1191px) {
    flex-direction: column;
  }
}

.dashboardCol {
  width: 50%;
  &:first-of-type {
    margin-right: 8px;
  }
  &:last-of-type {
    margin-left: 8px;
  }
  @media (max-width: 1191px) {
    width: 100%;
    &:first-of-type {
      margin-right: 0;
    }
    &:last-of-type {
      margin-left: 0;
    }
  }
  @media (max-width: $mobile-max-width) {
    height: 100%;
  }
}

.tableCol {
  padding: 7px 0;
  &Mobile {
    padding-bottom: 10px;
    margin-top: -2px;
  }
  @media (min-width: 1192px) {
    height: 100%;
    overflow-y: auto;
  }
}

.mapCol {
  @media (max-width: 1191px) {
    order: -1;
  }
  @media (max-width: $mobile-max-width) {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    height: calc(100% - 56px);
    order: 0;
  }
}

.map {
  position: relative;
  height: 100%;
  @media (min-width: $mobile-min-width) {
    padding: 10px 0;
    height: 50vh;
    min-height: 300px;
  }
  @media (min-width: 1192px) {
    height: calc(100% - 40px);
  }
}

.activateButton {
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &Green {
    background-color: #2aa451;
    padding: 0.375rem 1rem;
    color: white;
    &:hover {
      background-color: #1e7439;
      color: white;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 164, 81, 0.25);
    }
    i {
      vertical-align: middle;
    }
  }
}
.toggler {
  display: flex;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.25em;
  white-space: nowrap;
  &Button {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.5rem;
    color: #c6c6c6;
    border: none;
    &:focus,
    &:active {
      outline: none;
    }
    &:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 0.2em 0 0 0.2em;
    }
    &:last-child {
      border-radius: 0 0.2em 0.2em 0;
    }
    i {
      font-size: 24px;
    }
  }
  @media (min-width: $mobile-min-width) {
    display: none;
  }
}

.brandLogo {
  max-height: 40px;
  margin-left: 26px;
}

@media (max-width: $mobile-max-width) {
  .nav {
    flex-wrap: nowrap;

    > div {
      margin-bottom: 0 !important;
    }

    input,
    input:active,
    input:focus,
    :global(.input-group-text) {
      background-color: #ffffff00;
    }

    &:global(.navbar-dark) {
      input,
      input:active,
      input:focus,
      :global(.input-group-text) {
        border-color: rgba(255, 255, 255, 0.1);
      }
      input,
      input::placeholder,
      :global(.input-group-text) {
        color: #c6c6c6;
        font-size: 14px;
        height: 40px;
      }
    }

    &:global(.navbar-light) {
      input,
      input:active,
      input:focus,
      :global(.input-group-text) {
        border-color: rgba(0, 0, 0, 0.1);
      }

      input,
      input::placeholder,
      :global(.input-group-text) {
        color: rgba(0, 0, 0, 0.5);
      }

      .toggler {
        border-color: rgba(0, 0, 0, 0.1);
        &Button {
          color: rgba(0, 0, 0, 0.5);
          &:first-child {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}

.deviceTable {
  height: 100%;
  border-spacing: 0 2px;
  border-collapse: collapse;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  width: 100%;
}
