.wrapper {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.dateText {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
}

.text {
  font-size: 11px;
}