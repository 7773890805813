.statusLightContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusLightIcon {
  width: 18px;
  height: 18px;
  background: 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: inline-table;
}

.statusLightStatus {
  margin-left: 9px;
}
