.deviceView {
  height: 100%;
}

.dashboardColumns {
  min-height: calc(100% - 90px);
}

.map {
  position: relative;
  height: 50vh;
  padding: 0 0 10px 0;
  min-height: 300px;
  @media (min-width: 1192px) {
    height: calc(100% - 40px);
  }
}

@mixin columnCards {
  .deviceView {
    :global {
      .card {
        width: 100%;
        height: auto !important;
        margin-bottom: 8px;
      }
      .card-body {
        padding: 16px;

        .card-title {
          font-size: 26px;
        }

        .card-text {
          font-size: 14px;
          max-width: unset;
        }
      }
    }
  }
}

.dashboardCol {
  width: 50%;

  &:first-of-type {
    margin-right: 8px;
  }

  &:last-of-type {
    margin-left: 8px;
  }
}

.dashboardRow {
  display: flex;
}

.activateButton {
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardsRow {
  flex-wrap: wrap;
}

.insights {
  :global {
    .card {
      width: 31%;
      min-width: 175px;
    }
  }
}

.measurementsChart {
  padding: 50px 0.5rem 22px 0.5rem;
}

.summaryChart {
  padding: 10px 1rem;
}

.geozonesCheckbox {
  * {
    cursor: pointer;
  }
}

.selectView {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  padding-right: 30px;
  background-color: white;
  background-image: url('../../../assets/images/chevron-thin-down.svg');
  background-repeat: no-repeat;
  background-position: 93% 50%;
  background-size: 15px;
}

.insight__titles {
  width: 55%;
  align-items: normal;
  @media (min-width: 576px) {
    width: 100%;
    align-items: center;
  }
  @media (min-width: 992px) {
    align-items: center;
  }
}

.insight__title {
  font-size: 14px;
  @media (min-width: 576px) {
    font-size: 1.875rem;
  }
}

.insight__subtitle {
  font-size: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (min-width: 576px) {
    font-size: 1.875rem;
  }
}

.insight__backBtn {
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
  margin-left: auto;
  color: gray;
  font-size: 22px;
  &:hover {
    color: black;
    text-decoration: none;
  }
  &:before {
    content: '\f0a8';
    font-family: FontAwesome;
    margin-right: 5px;
  }
}

.insight__batteryLevel {
  margin: 0 20px;

  strong {
    @media (max-width: 576px) {
      display: none;
    }
  }

  i {
    vertical-align: middle;
    margin-right: 6px;
    @media (min-width: 577px) {
      display: none;
    }
  }
}

.insight__insightTitle {
  margin-right: auto;
  @media (max-width: 576px) {
    display: none;
  }
}

.insight__connected {
  > strong {
    white-space: normal;
  }
  white-space: nowrap;
}

@media (min-width: 1191px) and (max-width: 1345px) {
  @include columnCards;
}

@media (max-width: 1191px) {
  .dashboardCol {
    width: 100%;

    &:first-of-type {
      margin-right: 0;
    }

    &:last-of-type {
      margin-left: 0;
    }
  }

  .mapCol {
    order: -1;
  }

  .dashboardRow {
    flex-direction: column;
  }
}

.periodInput {
  background-color: white !important;
}

@media (max-width: 600px) {
  .cardsRow {
    flex-direction: column;
  }

  @include columnCards;

  .dashboardCol {
    table:not(.summaryTable) {
      width: 500px;
    }

    .activateButton {
      width: 90px;
    }
  }
}

.editLink {
  font-size: 14px;
  padding: 0.1rem 0.3rem;
  &:hover {
    background-color: darken(#2294f9, 5);
  }
}

.summaryTable {
  width: 100%;
  td {
    padding: 0;
  }
}

.spaceUnder > td {
  padding-bottom: 10px;
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
  @media (min-width: 576px) {
    flex-direction: row;
  }
}
