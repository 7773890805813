.mapMarker {
  position: relative;
  display: flex;
  width: max-content;
  align-items: center;
  transform: translate(-9px, -7px);
  z-index: 200;

  &:hover {
    cursor: pointer;
  }

  .statusLightIcon {
    width: 15px;
    height: 15px;
    background: 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    line-height: normal;
    margin-right: 5px;
  }

  &Active {
    font-weight: bold;
  }
}

.mapMarkerLabel {
  font-size: 16px;
  color: black;
  text-align: left;
  background: white;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.03);
  padding: 2px 6px;
}
