.dashboardTabs {
  font-size: 14px;
}

.dashboardTabs > ul > li {
  padding: 8px 0;
  border-bottom: 1px solid #d2d5d7;

  &:not(:first-child) {
    border-left: 1px solid #d2d5d7;
  }
}

.iconWithTitle {
  i {
    font-size: 12px;
  }
}
