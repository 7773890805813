@import '../../../../assets/stylesheets/_colors.scss';
@import '../../../../assets/stylesheets/utils/variables.scss';

.expandableTable {
  border-spacing: 0 2px;
  border-collapse: separate;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  width: 100%;
}
th,
td {
  margin: 0;
  padding: 0.45rem;
  color: $light-grey;
  position: relative;
}

.row {
  border-collapse: collapse;
  overflow: hidden;

  td {
    background-color: #ffffff;
    border-color: #d2d5d7;
    border-style: solid;
    border-width: 1px 0 1px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90px;
    @media (min-width: $mobile-min-width) {
      max-width: 100px;
    }
    @media (min-width: 1192px) {
      max-width: 80px;
    }
    @media (min-width: 1300px) {
      max-width: 115px;
    }
    @media (min-width: 1440px) {
      max-width: 185px;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
      border-width: 1px 0 1px 1px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
      border-width: 1px;
    }
  }
  &.expanded {
    td {
      &:first-child {
        border-radius: 5px 0 0 0;
      }

      &:last-child {
        border-radius: 0 5px 0 0;
      }
    }
    @media (max-width: $mobile-max-width) {
      td {
        &:nth-child(2) {
          border-radius: 5px 0 0 0px;
        }
      }
    }
  }

  @media (max-width: $mobile-max-width) {
    :global(.idCell),
    :global(.boxOpenedCell) {
      display: none;
    }
    :global(.statusCell) {
      border-left: 1px solid #d2d5d7;
      border-radius: 5px 0 0 5px;
    }
  }
}

.expandableRowWrapper {
  transform: translateY(-2px);

  td {
    padding: 0;
    border-color: #d2d5d7;
    background-color: #ffffff;
    border-style: solid;
    border-width: 0;
    transition: all 500ms;
    border-width: 0 1px 0 1px;
    border-radius: 0 0 5px 5px;
  }
}

.expandableRowWrapper.expanded {
  td {
    border-width: 0 1px 1px 1px;
  }
}

.expandableContent {
  text-align: left;
}

:global {
  .no-data-cell {
    border: 1px solid #d2d5d7;
    border-radius: 5px;
  }
}

.expandRowButton {
  cursor: pointer;
  position: absolute;
  top: 0;
  display: flex;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// map & device details style
.mapAndDeviceRow {
  height: 10%;
}

.mapAndDeviceContent {
  height: 90%;
}

.mapAndDeviceCollapsible {
  height: 100%;
}

.mapAndDeviceRowWrapper {
  transform: translateY(0px);
}

.icon {
  margin-top: 4px;
}
