.tooltipTrigger {
  cursor: pointer;
  display: inline-block;
}

.tooltip {
  position: fixed;
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  z-index: 9999;
  max-width: 120px;
  word-wrap: break-word;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-top-color: #333;
  }
}

.wrapText {
  white-space: normal;
  max-width: 240px;
  width: 100%;
}
